#page {
  position: relative;
  min-height: 100vh;
}

#body-wrap {
  padding-bottom: 270px;
}

.Hidden {
  opacity: 0;
  transition: opacity 0.2 ease-in-out;
}

.Multiline {
  white-space: pre-wrap;
}

#top {
  position: absolute;
  top: 0;
}

.Darken {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.Darken:hover {
  opacity: 1;
}

.FullHeight {
  height: 100%;
}

.Content {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}

#nav {
  text-align: center;
}

#nav-logo {
  height: 40px;
  width: 40px;
}

#nav-bar {
  justify-content: space-between;
  align-items: center;
}

.NavItems {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  align-items: center;
}

#about-section {
  padding: 40px 0;
  text-align: center;
  background-color: #00b9ff;
  color: white;
}

#hero {
  margin: 20px auto;
  width: 300px;
  height: 300px;
  border: solid 5px white;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
}

.SectionSpacer {
  height: 30px;
}

.SectionHeader {
  padding-top: 70px;
}

.HeaderSpacer {
  height: 140px;
}

#attributes-section {
  margin-top: -100px;
}

.Attributes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}

.AttributeTile {
  flex: 1 1 0;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 40px;
}

.GrayText {
  color: #666;
}

.BigIcon {
  font-size: 80px;
}

.PortfolioTiles {
  overflow: hidden;
  margin: 60px auto 0;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat( auto-fit, minmax(330px, 1fr));
}

.PortfolioProject {
  cursor: pointer;
  border-radius: 15px;
  overflow: hidden;
  height: 260px;
  position: relative;
}

.ProjectTypography {
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  text-align: left;
  box-sizing: border-box;
}

.ContactForm {
  margin: 40px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
  text-align: left;
  width: 50%;
}

.ActionButton {
  margin: 0 auto;
  width: max-content;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 130px;
  background-color: #eee;
  padding: 40px 0 0;
}

.InfoCard {
  margin: 0 auto;
  width: 400px;
  padding: 20px 40px;
  text-align: center;
  border-radius: 15px;
  box-sizing: border-box;
  margin-top: 30px;
}

/* Tablet/small window */
@media only screen and (max-width : 1300px) {
  .Content {
    width: 860px;
  }

  .Attributes {
    display: flex;
    grid-gap: 0;
    border-radius: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  .AttributeTile {
    border-radius: 0;
    box-shadow: none;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .ContactForm {
    width: 80%;
  }
}

@media only screen and (max-width : 920px) {
  #hero {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }

  #about-section {
    padding: 10px 0;
  }

  .Attributes {
    margin: 0;
    box-sizing: border-box;
    width: 100%;
  }
  
  .Content {
    width: 660px;
  }

  .ProjectTypography {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    padding: 5px 10px;
  }

  .Darken {
    background: none;
    opacity: 1;
  }

  .ContactForm {
    flex-direction: column;
    text-align: left;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    grid-gap: 20px;
  }
}

@media only screen and (max-width : 720px) {
  #page {
    overflow-x: hidden;
  }
  
  #nav-title, .AnchorButton {
    display: none !important;
  }

  #contact-button {
    display: inline-flex !important;
  }

  #contact-section {
    margin-top: 30px;
  }

  .NavItems {
    grid-template-columns: auto;
  }

  .Content {
    width: initial;
  }

  .SectionHeader {
    padding-top: 0;
  }

  .Attributes, .PortfolioTiles {
    flex-direction: column;
    box-sizing: border-box;
  }  

  .Attributes, .PortfolioTiles, .PortfolioProject {
    box-shadow: none;
    margin: 0;
    grid-gap: 0;
    border-radius: 0;
    padding-bottom: 0;
  }

  .AttributeTile {
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .PortfolioTiles {
    margin: 60px 30px;
    border-radius: 15px;
  }

  .ContactForm {
    display: flex;
    grid-gap: 0;
    margin-bottom: 20px;
  }

  .ContactField {
    margin-top: 20px;
  }

  .ActionButton {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width : 600px) {

  #title {
    font-size: 48px !important;
    font-weight: 400 !important;
  }

  #subtitle {
    font-size: 20px !important;
  }

  .PortfolioTiles {
    border-radius: 0;
    margin: 60px 0 0;
  }

  .InfoCard {
    width: initial;
    margin: 30px 20px 0;
  }
}